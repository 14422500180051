/* eslint-env browser */
import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { Container, Grid } from "@mui/material";
import { Public } from "@mui/icons-material";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import Developer from "../components/Stack.js";
import SearchContext from "../contexts/SearchContext.js";
import useFilter from "@tzmedical/react-hooks/useFilter";

//---------------------------------------------------------------------------
// List of stacks that are public
//---------------------------------------------------------------------------
const publicStacks = [
  { suffix: "alpha", domain: "bitrhythm.io", name: "Alpha", Icon: Public },
  { suffix: "beta", domain: "bitrhythm.io", name: "Beta", Icon: Public },
  {
    suffix: "preview",
    domain: "bitrhythm.io",
    urls: {phpMyAdmin: "https://phpmyadmin.bitrhythm.io"},
    name: "Preview",
    Icon: Public,
  },
  { suffix: "", domain: "bitrhythm.io", name: "Stable", Icon: Public },
];

const PublicStacks = () => {
  //---------------------------------------------------------------------------
  // Search support
  //---------------------------------------------------------------------------
  const { search } = React.useContext(SearchContext);
  const searchFields = {
    name: "name",
  };
  const filteredStacks = useFilter(publicStacks, search, searchFields);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <Container maxWidth="xl">
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        {filteredStacks.map((stack) => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={stack.suffix}>
            <Developer stack={stack} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PublicStacks;
